<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.orders") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="card">
      <custom-table
        :fields="fields"
        :items="orders"
        :loading="waiting"
        :pagination="true"
        :perPage="10"
        :searchable="true"
        :searchFields="searchFields"
        :showHeader="true"
        sortBy="createdAt"
        :sortFields="sortFields"
        @row-click="(id) => router.push(`/orders/${id}`)"
      >
        <template #cell(createdAt)="{ value }">
          {{ getLocaleDate(locale, value) }}
        </template>
        <template #cell(price)="{ value }">
          {{ value / 100 }}
        </template>
        <template #cell(status)="{ value }">
          {{ t(`payment_statuses.${value}`) }}
        </template>
        <template #cell(delivered)="{ value }">
          {{ value ? t("labels.yes") : t("labels.no") }}
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
import { PlusIcon } from "@heroicons/vue/24/solid";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import CustomTable from "../components/table/CustomTable.vue";
import { getLocaleDate } from "../utils/dateUtils";
export default {
  components: { Breadcrumb, CustomTable, FormButton, PlusIcon },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { locale, t } = useI18n();

    const company = computed(() => store.state.company.company);
    const estate = computed(() => store.state.estate.estate);
    const orders = computed(() => store.state.order.orders);
    const user = computed(() => store.state.authentication.user);

    const fields = ref([
      {
        key: "id",
        label: "id",
      },
      {
        key: "createdAt",
        label: "created_at",
      },
      {
        key: "price",
        label: "price",
      },
      {
        key: "status",
        label: "payment_status",
      },
      {
        key: "delivered",
        label: "delivered",
      },
    ]);
    const searchFields = ref(["id", "createdAt", "price"]);
    const sortFields = ref(["createdAt", "price"]);

    onMounted(() => {
      store.dispatch("order/getOrders", {
        estateId: estate.value.id,
        userId: user.value.id,
      });
    });

    return {
      company,
      fields,
      getLocaleDate,
      locale,
      orders,
      PlusIcon,
      router,
      searchFields,
      sortFields,
      t,
      waiting: computed(() => store.state.order.waiting),
    };
  },
};
</script>
